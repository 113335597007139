import { getAuth, onAuthStateChanged } from "firebase/auth";


export default defineNuxtRouteMiddleware(async (to, from) => {

  if (process.server) return;

  const store = useStore();

  store.previousRoutePath = from.path;

  await new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      unsubscribe();
      resolve(null);
    }, reject);
  });


  switch (to.path) {

    case '/register':
    case '/login':
    case '/forgot-password':
    case '/update-password':
      store.loadingPage = false;
      if (store.isLoggedIn) {
        const { completed: registrationCompleted } = await API.getRegistrationStatus();
        if (!registrationCompleted) return navigateTo('/complete-registration');
        navigateTo('/');
      }
      break;

    default:
      if (!store.isLoggedIn) return navigateTo('/login');
      const { completed: registrationCompleted } = await API.getRegistrationStatus();
      if (!registrationCompleted && to.path !== '/complete-registration') {
        return navigateTo('/complete-registration');
      }
      break;

  }

});
